@supports (min-height: 100dvh) {
  .page[data-v-e56366] {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .page[data-v-e56366] {
    min-height: 100vh;
  }
}

.credit-form[data-v-44e061] {
  --track-bg: var(--c-light-telegray);
  --track-fill: var(--c-blue);
  background-color: var(--c-white);
  border-radius: 3vmin;
  width: min(100%, 540px);
  margin: auto;
  padding: max(4vmin, 15px);
  box-shadow: 0 8px 24px #959da533;
}

.credit-form__range-markers[data-v-44e061] {
  color: var(--c-gray);
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  gap: 0;
  width: calc(100% - 32px);
  font-size: 11px;
  display: grid;
  transform: translate(16px, -12px);
}

.credit-form__range input[type="text"][data-v-44e061] {
  padding: 0 16px 3px;
  font-size: 2.4em;
}

.credit-form__phone input[type="text"][data-v-44e061] {
  --tf-c-placeholder: DarkGray;
  --tf-transition-easing: cubic-bezier(0, .202, .204, 1);
  padding: 0 16px;
  font-size: 16px;
}

.credit-form__phone input[type="text"][data-v-44e061]::placeholder {
  color: var(--tf-c-placeholder);
  transition: opacity .225s var(--tf-transition-easing);
}

.credit-form__phone input[type="text"][data-v-44e061]:focus::placeholder {
  opacity: .4;
}

.credit-form input[type="text"][data-v-44e061] {
  background-color: var(--c-white);
  color: var(--c-almost-black);
  box-shadow: 0 0 0 1px inset var(--track-bg);
  border: none;
  border-radius: 5.5px;
  width: 100%;
  height: 64px;
  margin: 0;
}

.credit-form input[type="text"][data-v-44e061]:focus {
  outline: none;
}

.credit-form input[type="range"][data-v-44e061] {
  appearance: none;
  cursor: pointer;
  background-color: var(--track-bg);
  border-radius: 3.5px 3.5px 0 0;
  outline: none;
  width: calc(100% - 30px);
  height: 6px;
  margin: 10px 0;
  padding: 0;
  transform: translate(15px, -16px);
}

.credit-form input[type="range"][data-v-44e061]::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--c-white);
  box-shadow: 0 0 0 4px inset var(--track-fill);
  border: none;
  border-radius: 30px;
  width: 20px;
  height: 20px;
}

.credit-form .tickbox[data-v-44e061] {
  --tickbox-label-font-color: var(--c-gray);
  --tickbox-color-active: var(--c-blue);
}

.credit-form .tickbox input[type="checkbox"][data-v-44e061] {
  margin-right: 0 !important;
}

.credit-form .btn[data-v-44e061] {
  letter-spacing: .5px;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: var(--c-blue);
  color: var(--c-white);
  border: none;
  border-radius: 5.5px;
  place-content: center;
  width: 100%;
  height: 64px;
  margin: 15px auto;
  padding-top: 2px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  transition: background-color .375s ease-in-out;
  display: grid;
}

.credit-form .btn.inactive[data-v-44e061] {
  pointer-events: none;
  background-color: var(--c-light-telegray);
}

.image-container[data-v-061f81] {
  width: 100%;
  height: 100%;
}

.image-container picture[data-v-061f81] {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img[data-v-061f81] {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.credit-banners[data-v-7ef30f] {
  grid-template: 1fr / 1fr 1fr;
  gap: 0 max(4vmin, 15px);
  width: min(100%, 540px);
  margin: auto;
  display: grid;
}

.credit-banners__item[data-v-7ef30f] {
  grid-template: 1fr auto / 1fr;
  gap: 5px 0;
  display: grid;
}

.credit-banners__item-title[data-v-7ef30f] {
  padding-left: 7px;
}

.credit-banners__item .image-container[data-v-7ef30f] {
  border: 6px solid var(--c-white);
  border-radius: 5.5px;
  overflow: hidden;
}

.page[data-v-e56366] {
  place-content: start center;
  gap: max(3vmin, 20px) 0;
  padding: 50px 0;
  display: grid;
  position: relative;
}

@supports (min-height: 100dvh) {
  .page[data-v-e56366] {
    min-height: 100dvh;
  }
}

@supports not (min-height: 100dvh) {
  .page[data-v-e56366] {
    min-height: 100vh;
  }
}

.page__logo[data-v-e56366] {
  justify-self: center;
}
/*# sourceMappingURL=index.dda03ea0.css.map */
